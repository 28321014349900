<template>
<div class="row tgju-widgets-row dashboard-widgets-calendar-list dash-full-height">
    <div class="col-12">
        <div class="tgju-widget light">
            <div class="list-widget widget-head-page list-row bold list-row-title">
                <div class="list-col l-title head-color">
                    <span class="with-icon"><i class="uil uil-calendar-alt"></i> جدول تقویم اقتصادی</span>
                </div>
                <div class="list-col l-item pointer hover head-color">
                    <span>
                        <div class="fa fa-question-circle l-item-btn-pages"></div> راهنما
                    </span>
                </div>
            </div>
            <div class="tgju-widget-content full-padding">
                <Calendar />
            </div>
        </div>
    </div>
</div>
</template>

<script>
// این ویو برای جدول تقویم اقتصادی استفاده میشود
import Calendar from '@/components/CalendarTable.vue'

export default {
    name: 'CalendarTable',
    components: {
        Calendar,
    },
    data: function () {
        return {
            widget: {},
            data: {},
        }
    },
}
</script>
