<template>
<div class="widget-min-box">
    <slot v-if="widgetLoad">
        <div class="text-center p-2">
            <div class="widget-loading"></div>
        </div>
    </slot>
    <div v-else class="tgju-widget light">
        <div v-for="(item,index ) in calendarTables" :key="index">
            <h4 class="text-center p-3"> {{ index + '  -  ' + toRelativeDate(index) }} </h4>
            <table class="table table-hover text-center">
                <thead class="text-center">
                    <tr style="width: 100%">
                        <th style="width: 50px;"></th>
                        <th style="width: 50px;"></th>
                        <th style="width: 50px;"></th>
                        <th style="width: 100px;">واقعی</th>
                        <th style="width: 100px;">پیش بینی</th>
                    </tr>
                </thead>
                <tbody class="table-padding-sm">
                    <tr style="width: 100%" v-for="calendarTable in item" :key="calendarTable.id">
                        <td style="width: 50px;">
                            {{ toRelativeTime(calendarTable.datetime) }}
                        </td>
                        <td style="width: 50px;">
                            {{ calendarTable.country }}
                            <i :class="'dayCalendar-flag mini-flag flag-' +  calendarTable.country.toLowerCase() "></i>
                        </td>
                        <td style="width: 50px;text-align: right;">
                            {{ calendarTable.title }}
                        </td>
                        <td style="width: 50px;">
                            {{ !calendarTable.actual ? '-' : calendarTable.actual  }}
                        </td>
                        <td style="width: 100px;">
                            {{ !calendarTable.forecast ? '-' : calendarTable.forecast }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
</template>

<script>
// این کامپوننت اجزای جدول تقویم اقتصادی را دارا می باشد
import moment from "moment-jalaali";

export default {
    name: 'CalendarTable',
    data() {
        return {
            widgetLoad: true,
            calendarTables: []
        }
    },
    mounted() {
        // در خواست لود اطلاعات برای تقویم اقتصادی
        this.loadData();
    },
    methods: {
        // این متد تاریخ میلادی را به تاریخ شمسی تبدیل می کند
        toRelativeDate(date) {
            return moment(date).format('jYYYY/jM/jD');
        },
        // این متد زمان را به زمان رلتیو تبدیل می کند
        toRelativeTime(date) {
            return moment(date).format('HH:mm');
        },
        // این متد درخواست دریافت آخرین داده های تقویم اقتصادی را به سرور ارسال می کند
        loadData() {
            this.$helpers.makeRequest('POST', '/calendar/calendar-table', {
                market_ids: this.market_ids
            }).then(api_response => {
                if (api_response.status == 200) {
                    this.calendarTables = api_response.data.response.detail.response.events;
                    this.widgetLoad = false;
                }
            });
        },
    },
}
</script>
